import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Button from "./button"
import { faSlideshare } from "@fortawesome/free-brands-svg-icons"

import {
    slideEmbedding,
    bottomViewButtonContainer
} from "../templates/talk.module.scss"
import fetch from "node-fetch"
import { isSlideShareUrl } from "../utils/talk-util"

const getSlideshareData = async (url) => {
    const apiUrl = `https://www.slideshare.net/api/oembed/2?url=${url}&format=json`
    const response = await fetch(apiUrl, {
        credentials: "omit",
        "headers": {
            "Content-Type": "application/jsonp"
        }
    })
    // Check if error
    const retrievedResponse = await response
    if (retrievedResponse.status === 404) {
        return {}
    }
    return retrievedResponse.json()
}

const getSlideshareEmbedUrl = (slideshareData) => {
    return slideshareData.html.match(/"(.*?)"/)[1]
}



// Function to convert Dropbox share links to direct links
const getDirectDropboxLink = (url) => {
    try {
        const parsedUrl = new URL(url);
        if (
            parsedUrl.hostname === 'www.dropbox.com' ||
            parsedUrl.hostname === 'dropbox.com'
        ) {
            return url.replace('www.dropbox.com', 'dl.dropboxusercontent.com')
                .replace('&dl=0', '');
        } else {
            return url;
        }
    } catch (e) {
        console.error("Invalid Dropbox URL:", e);
        return url;
    }
}

const Slides = ({ url, fields }) => {
    const [embedUrl, setEmbedUrl] = useState('');

    useEffect(() => {
        const fetchEmbedUrl = async () => {
            if (url) {
                try {
                    const parsedUrl = new URL(url);
                    const pathname = parsedUrl.pathname;
                    const fileExtension = pathname.substring(pathname.lastIndexOf('.')).toLowerCase();
                    if (fileExtension === '.ppt' || fileExtension === '.pptx') {
                        // Convert Dropbox share link to direct link if necessary
                        const directUrl = getDirectDropboxLink(url);
                        // Construct the embed URL using Office Online Viewer
                        setEmbedUrl(`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(directUrl)}`);
                    } else if (isSlideShareUrl(url)) {
                        const slideshareData = await getSlideshareData(url);
                        if (slideshareData && slideshareData.html) {
                            setEmbedUrl(getSlideshareEmbedUrl(slideshareData));
                        }
                    }
                } catch (e) {
                    // Invalid URL
                    console.error("Invalid URL:", e);
                }
            }
        };

        fetchEmbedUrl();
    }, [url, fields]);

    return (
        <>

            {embedUrl &&
                <div className={slideEmbedding}>
                    <iframe
                        src={embedUrl}
                        width="427"
                        title="Slides"
                        height="356"
                        frameBorder="0"
                        marginWidth="0"
                        marginHeight="0"
                        scrolling="no"
                        allowFullScreen
                    ></iframe>
                </div>
            }


            <div className={bottomViewButtonContainer}>
                <Button icon={faSlideshare}
                        link={url}
                        type={"primary"}
                        text={"View" + (url && url.indexOf("https://www.slideshare.net") > 0 ? " on SlideShare" : " slides")}/>

            </div>
        </>
    )
}
Slides.propTypes = {
    url: PropTypes.string,
    fields: PropTypes.object
}
export default Slides
