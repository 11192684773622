// extracted by mini-css-extract-plugin
export var activeTabButton = "talk-module--active-tab-button--0893f";
export var backgroundColor = "#141821";
export var borderRadius = ".15rem";
export var bottomViewButtonContainer = "talk-module--bottom-view-button-container--7dbbd";
export var locationLogo = "talk-module--location-logo--f14db";
export var logoContainer = "talk-module--logoContainer--9aadb";
export var maxModalWidth = "48em";
export var modalBackgroundColor = "rgba(20,24,33,.75)";
export var slideEmbedding = "talk-module--slide-embedding--b8aeb";
export var tabButton = "talk-module--tab-button--9e6d8";
export var talkMetadata = "talk-module--talk-metadata--eeb8f";
export var videoEmbedding = "talk-module--video-embedding--d8abd";