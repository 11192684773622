const { faCrown, faHandHoldingHeart, faUser } = require("@fortawesome/free-solid-svg-icons")
const roleDetails = {
    "solo": {
        name: "Solo project",
        icon: faUser
    },
    "lead": {
        name: "Project lead",
        icon: faCrown
    },
    "collaborator": {
        name: "Project collaborator",
        icon: faHandHoldingHeart
    }
}

const getRoleName = function(role) {
    const details = roleDetails[role.toLowerCase()]
    if (details && details.name) {
        return details.name
    }
    return role
}

const getRoleIcon = function(role) {
    const details = roleDetails[role.toLowerCase()]
    if (details && details.icon) {
        return details.icon
    }
    return faUser
}

module.exports = { getRoleName, getRoleIcon }


