import React from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getRoleIcon, getRoleName } from "../utils/role-util"
import {
    icon,
    project,
    projectContainer,
    projectInfo,
    projectMeta,
    projectsContainer,
    role,
    year
} from "./project-list.module.scss"

function getYear(node) {
    return node.frontmatter.date ? node.frontmatter.date.substring(0, 4) : "?"
}


const ProjectList = ({ projects }) => {
    return (
        <section className={projectsContainer}>
            {projects.map(( node ) => (
                <Link to={node.fields.slug} key={node.id} className={projectContainer}>
                    <div className={project}>
                        <GatsbyImage
                            image={node.frontmatter.cover.childImageSharp.gatsbyImageData}
                            placeholder="blurred"
                            objectFit="cover"
                            objectPosition="50% 50%"
                            alt=""
                            key={node.id}/>
                        <div className={projectMeta}>
                            <div className={year}>
                                {getYear(node)}
                            </div>
                            <div className={role}>
                                <FontAwesomeIcon icon={getRoleIcon(node.frontmatter.role)} className={icon}/>
                                <span>
                                    {getRoleName(node.frontmatter.role)}
                                </span>
                            </div>
                        </div>

                        <div className={projectInfo}>
                            <h2>
                                {node.frontmatter.title}
                            </h2>
                            <p>
                                {node.frontmatter.description}
                            </p>

                        </div>
                    </div>
                </Link>
            ))}
        </section>
    )
}
ProjectList.propTypes = {
    projects: PropTypes.array.isRequired
}
export default ProjectList

export const query = graphql`fragment ProjectOverviewFragment on Mdx {
  fields {
    slug
  }
  id
  frontmatter {
    title
    role
    cover {
      id
      childImageSharp {
        gatsbyImageData(
          width: 660,
          placeholder: BLURRED,
          formats: [AUTO, PNG, WEBP],
          layout: CONSTRAINED)
      }
      icon: childImageSharp {
          gatsbyImageData(
              width: 64,
              placeholder: BLURRED,
              formats: [AUTO, PNG, WEBP],
              layout: CONSTRAINED)
      }
    }
    date(formatString: "YYYY")
    slug
    description
    tags
    priority
  }
}
`
