import React, { useState } from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import Button from "../components/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getTalkTypeName, isYoutubeVideo, getYoutubeEmbedUrl } from "../utils/talk-util"
import NewlineText from "../components/newline-text"
import PeopleList from "../components/people-list"
import PaperList from "../components/paper-list"
import ProjectList from "../components/project-list"
import { faSlideshare } from "@fortawesome/free-brands-svg-icons"
import {
    faArrowLeft,
    faCalendarDay,
    faChalkboardTeacher,
    faFilePdf,
    faComment,
    faGlobe,
    faPenNib,
    faScroll,
    faQuoteLeft,
    faRocket,
    faUser,
    faVideo,
    faCamera,
    faBookReader,
    faLink,
    faExternalLinkAlt,
    faClock,
    faLanguage,
    faUsers
} from "@fortawesome/free-solid-svg-icons"
import {
    talkMetadata,
    bottomViewButtonContainer,
    videoEmbedding,
    tabContainer,
    tabButton,
    activeTabButton
} from "./talk.module.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SocialMediaPostList from "../components/social-media-post-list"
import PictureList from "../components/picture-list"
import Breadcrumb from "../components/breadcrumb"
import { getMainPicture } from "../utils/picture-util"
import Slides from "../components/slides"

const TalkTypePage = ({ data }) => {
    const [activeTab, setActiveTab] = useState("english")
    const talkType = data.allFile.edges[0].node.childTalkTypesJson
    const talkTypeVariation = talkType.languages && talkType.languages.find(variation => variation.language === "english")
    const title = talkType.topic || talkType.title || talkTypeVariation.title

    return (
        <>
            <Seo title={title} description={talkTypeVariation.description} />
            <Breadcrumb
                pages={[
                    { name: "Thomas Winters", link: "/" },
                    { name: "Talks", link: "/talks" },
                    { name: "Talk Types", link: "/talks#types" },
                    { name: title },
                ]}
            />
            <h1>{title}</h1>

            <section>
                {talkType.cover && (
                    <GatsbyImage
                        image={getImage(talkType.cover.childImageSharp)}
                        alt={title}
                        placeholder="blurred"
                    />
                )}
            </section>

            <section>
                <h2>Talk Information</h2>
                <ul className="fa-ul">

                    {talkType.duration && (
                        <li>
                            <FontAwesomeIcon icon={faClock} listItem />
                            <strong>Duration: </strong>
                            {talkType.duration}
                        </li>
                    )}
                    {/*<li>*/}
                    {/*    <FontAwesomeIcon icon={faLanguage} listItem/>*/}
                    {/*    <strong>Available languages: </strong>*/}
                    {/*    {talkType.languages.map(language => language.language).join(", ")}*/}

                    {/*</li>*/}
                    {/*{talkType.type && (*/}
                    {/*    <li>*/}
                    {/*        <FontAwesomeIcon*/}
                    {/*            icon={faChalkboardTeacher}*/}
                    {/*            listItem*/}
                    {/*        />*/}
                    {/*        <strong>Type: </strong>*/}
                    {/*        {talkType.type}*/}
                    {/*    </li>*/}
                    {/*)}*/}
                    {talkType.audience && (
                        <li>
                            <FontAwesomeIcon icon={faUsers} listItem />
                            <strong>Audience: </strong>
                            {talkType.audience}
                        </li>
                    )}
                    {talkType.languages?.length > 1 && (
                        <li>
                            <FontAwesomeIcon icon={faLanguage} listItem />
                            <strong>Available languages: </strong>

                            <span className={tabContainer}>
                                {talkType.languages.map(language => (
                                    <button
                                        key={language.language}
                                        className={`${tabButton} ${activeTab === language.language ? activeTabButton : ""}`}
                                        onClick={() => setActiveTab(language.language)}
                                    >
                                        {language.language.charAt(0).toUpperCase() + language.language.slice(1)}
                                    </button>
                                ))}
                            </span>
                        </li>
                    )}
                    {talkType.languages.map(language => (
                        activeTab === language.language && (
                            <>
                                <li>
                                    <FontAwesomeIcon icon={faScroll} listItem />
                                    <strong>Title: </strong>
                                    {language.title}
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faQuoteLeft} listItem />
                                    <strong>Description: </strong>
                                    {language.description}
                                </li>
                                    {language.bio && (
                                        <li>
                                            <FontAwesomeIcon icon={faBookReader} listItem />
                                            <strong>Speaker bio: </strong>
                                            {language.bio}
                                        </li>
                                    )}
                                    {language.slides && (
                                        <li>
                                            <FontAwesomeIcon icon={faSlideshare} listItem />
                                            <strong>Slides</strong>
                                            <Slides url={language.slides} fields={talkType.fields} />
                                        </li>
                                    )}
                            </>
                        )
                    ))}
                </ul>



            </section>

            <section className={talkMetadata}>
                {talkType.links && (
                    <div>
                        <h3>Links</h3>
                        <ul className="fa-ul">
                            {talkType.links.map(link => (
                                <li key={link.title}>
                                    <FontAwesomeIcon icon={faExternalLinkAlt} listItem />
                                    <a href={link.url} className="underline" target="_blank" rel="noopener noreferrer">
                                        {link.title}
                                    </a>
                                    {link.description && <span>: {link.description}</span>}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </section>

            {talkType.video && isYoutubeVideo(talkType.video) && (
                <section>
                    <h2>
                        <FontAwesomeIcon
                            icon={faVideo}
                            className={"fa-fw header-icon"}
                            size={"sm"}
                        />
                        Video
                    </h2>
                    <div className={videoEmbedding}>
                        <iframe
                            src={getYoutubeEmbedUrl(talkType.video)}
                            width="560"
                            height="349"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </section>
            )}
            {talkType.video && !isYoutubeVideo(talkType.video) && (
                <section className={bottomViewButtonContainer}>
                    <Button
                        icon={faFilePdf}
                        link={talkType.video}
                        type={"primary"}
                        text={"View talkType"}
                        size={"lg"}
                    />
                </section>
            )}

            <section>
                <Button
                    icon={faArrowLeft}
                    link={"/talks#types"}
                    local={true}
                    text={"Back to all talk types"}
                />
            </section>
        </>
    )
}

export default TalkTypePage

export const query = graphql`query ($json: String!) {
    allFile(filter: {relativePath: {regex: $json}}) {
        edges {
            node {
                id
                childTalkTypesJson {
                    ...TalkTypeFragment
                    title
                    type
                    topic
                    languages {
                        language
                        title
                        description
                        slides
                    }
                    duration
                    audience
                    bio
                    main
                    priority
                    links {
                        title
                        url
                        description
                    }
                    relatedTalks {
                        ...TalkFragment
                    }
                    cover {
                        childImageSharp {
                            gatsbyImageData(
                                width: 1000,
                                placeholder: BLURRED,
                                formats: [AUTO, PNG, WEBP],
                                layout: CONSTRAINED
                            )
                        }
                    }
                }
            }
        }
    }
}
`
