// extracted by mini-css-extract-plugin
export var backgroundColor = "#141821";
export var borderRadius = ".15rem";
export var icon = "project-list-module--icon--9acb2";
export var maxModalWidth = "48em";
export var modalBackgroundColor = "rgba(20,24,33,.75)";
export var project = "project-list-module--project--5e0b2";
export var projectContainer = "project-list-module--project-container--0532a";
export var projectInfo = "project-list-module--project-info--96ba3";
export var projectMeta = "project-list-module--project-meta--08f13";
export var projectsContainer = "project-list-module--projects-container--bc65a";
export var role = "project-list-module--role--0cc03";
export var year = "project-list-module--year--9dae4";